import React, {useState} from 'react';
import MainScreen from "../../components/MainScreen/MainScreen";
import s from "./Contacts.module.css";
import {useFormik} from "formik";
import {Api} from "../../api/Api";
import * as yup from 'yup';

const validationSchema = yup.object({
    email: yup.string().required('Required field').email('invalid format'),
})

const Contacts = () => {

    const [contactResponse, setContactResponse] = useState('')

    const useContactsFormik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            message: '',
        },
        validationSchema,
        onSubmit : values => {
            console.log(values)
            const preparedDataForSending = {
                project: "hottinger",
                subject: "Hello!",
                data : values
            }
            Api.sendContactMessage(preparedDataForSending)
                .then((res: any) => {
                    if (res.data === "Project Not Found") {
                        setContactResponse('OOPS! Something went wrong')
                        return
                    }
                    setContactResponse('Your message was successfully sent')
                }).catch(() => {
                    setContactResponse('OOPS! Something went wrong')
            })
        }
    })

    return (
        <div className={s.wrapper}>
            <MainScreen/>
            <div className={s.flex}>
                <form onSubmit={useContactsFormik.handleSubmit} className={s.form_block}>
                    <div className={s.title}>You may also contact us through our enquiry form:</div>
                    <div className={s.flex}>
                        <input type="text"
                               className={s.input}
                               placeholder={"Name"}
                               id={"name"}
                               name={"name"}
                               value={useContactsFormik.values.name}
                               onChange={useContactsFormik.handleChange}
                        />
                        <input type="text"
                               className={useContactsFormik.errors.email ? s.error_input : s.input}
                               placeholder={"Email (test@gmail.com)"}
                               id={"email"}
                               name={"email"}
                               value={useContactsFormik.values.email}
                               onChange={useContactsFormik.handleChange}
                        />
                    </div>
                    <input type="text"
                           className={s.input}
                           placeholder={"Phone"}
                           id={"phone"}
                           name={"phone"}
                           value={useContactsFormik.values.phone}
                           onChange={useContactsFormik.handleChange}
                    />
                    <textarea className={s.textarea}
                              placeholder={"Your Message"}
                              id={"message"}
                              name={"message"}
                              value={useContactsFormik.values.message}
                              onChange={useContactsFormik.handleChange}
                    />
                    <button className={s.my_btn}>Send</button>
                </form>
                <div className={s.contacts_block}>
                    <p>
                        <b>Address:</b> Jelenia 1, Bratislava 81105, Slovakia
                    </p>
                    <p>
                        <b>Telephone:</b> +421908837842
                    </p>
                    <p>
                        <b>Email:</b> info@hottinger-shipping.com
                    </p>
                </div>
            </div>
            {contactResponse.length > 0 && <div className={s.contact_response}>{contactResponse}</div>}
        </div>

    );
};

export default Contacts;
