import React, {useState} from 'react';
import s from "./MainScreen.module.css";
import {NavLink} from "react-router-dom";
import Slider from "react-slick";
import prevItem from "../../assets/img/arrow_left.png";
import nextItem from "../../assets/img/arrow_right.png";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const MainScreen = () => {

    const cards = [
        {
            number: 1,
            title: 'Special Operations',
            bgURL: "/img/main_screen_1.jpg"
        },
        {
            number: 2,
            title: 'Vaulting Facilities',
            bgURL: "/img/main_screen_2.jpg"
        },
    ]

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    };

    const [sliderRef, setSliderRef] = useState<any>(null)

    return (
        <>
            <div className={s.header}>
                <div className={s.container}>
                    <div className={s.login}>Login to My Hottingershipping</div>
                </div>
            </div>

            <div className={s.container}>
                <div className={s.title}>
                    HOTTINGER MERCHANT SHIPPING & TRADE
                </div>
                <div className={s.navbar}>
                    <NavLink className={s.nav_item} to={"/"}>Home</NavLink>
                    <NavLink className={s.nav_item} to={"#"}>About</NavLink>
                    <NavLink className={s.nav_item} to={"#"}>Secured Delivery</NavLink>
                    <NavLink className={s.nav_item} to={"#"}>FTZ and Secure Storage Facilities</NavLink>
                    <NavLink className={s.nav_item} to={"#"}>Customs Brokerage</NavLink>
                    <NavLink className={s.nav_item} to={"#"}>Precious Metals</NavLink>
                    <NavLink className={s.nav_item} to={"#"}>Vaults & FTZ Facilities</NavLink>
                    <NavLink className={s.nav_item} to={"#"}>Vaulting Facilities</NavLink>
                    <NavLink className={s.nav_item} to={"/contact_us"}>Contact Us</NavLink>
                </div>
            </div>

            <div className={s.slider_wrapper}>
                <Slider ref={setSliderRef} {...settings} className={s.my_slider}>
                    {cards.map((card) => <div key={card.number}
                                              className={s.active}>
                        <div className={s.card_content}>

                            <div className={s.text_slider}>
                                {card.title}
                            </div>

                            <div onClick={sliderRef?.slickPrev}>
                                <img src={prevItem} alt="" className={s.arrow_prev}/>
                            </div>
                            <div onClick={sliderRef?.slickNext}>
                                <img src={nextItem} alt="" className={s.arrow_next}/>
                            </div>
                        </div>

                        <div className={s.bg}>
                            <img src={card.bgURL} alt=""/>
                        </div>
                    </div>)}
                </Slider>
            </div>
        </>
    );
};

export default MainScreen;
