import React, {Dispatch, SetStateAction} from 'react';
import s from './TrackingPopup.module.css'
import close from '../../assets/img/close.png'
import search_icon from '../../assets/img/search.png'
import {Popover, Steps, StepsProps} from "antd"
import './index.css'
import {ResponseType} from "../../pages/Home/Home";

type PropsType = {
    trackingNumber: string
    setTrackingNumber: Dispatch<SetStateAction<string>>
    setPopupIsShow: Dispatch<SetStateAction<boolean>>
    response: ResponseType
    getDeliveryInfo: () => void
    error: boolean
}

const customDot: StepsProps['progressDot'] = (dot, {status, index}) => (
    <Popover
        content={
        <span>
            step {index + 1} status: {status}
        </span>
    }>
        <div className={status === "process" ? s.process_dot : (status === "finish" ? s.finish_dot : s.dot_style)}>
            {dot}
        </div>
        {/*<img src={close} alt=""/>*/}
    </Popover>
);

const TrackingPopup = (props: PropsType) => {
    return (
        <div className={s.popup_wrapper}>
            <div className={s.content}>
                <img src={close} alt="" className={s.close_icon} onClick={() => props.setPopupIsShow(false)}/>
                <div className={s.search_block}>
                    <input type="text"
                           className={s.search_input}
                           value={props.trackingNumber}
                           onChange={(e) => props.setTrackingNumber(e.currentTarget.value)}
                    />
                    <button className={s.search_button} onClick={() => props.getDeliveryInfo()}>
                        <img src={search_icon} alt=""/>
                        Track your shipment
                    </button>
                </div>
                {props.error && <div className={s.error}>Wrong shipping reference</div>}
                <Steps
                    current={props.response.status - 1}
                    progressDot={customDot}
                    items={[
                        {
                            className: 'step_style test_img_processing',
                            title: 'Processing',
                            description: 'Your shipment has been taken in charge by local Hottinger office',
                        },
                        {
                            className: 'step_style test_img_transit',
                            title: 'In transit',
                            description: 'Your shipment is moving towards the next Hottinger office',
                        },
                        {
                            className: 'step_style test_img_out',
                            title: 'Out For Delivery',
                            description: 'Your shipment has left Hottinger office and will be delivered soon',
                        },
                        {
                            className: 'step_style test_img_delivery',
                            title: 'Delivered',
                            description: 'Your shipment has been delivered',
                        },
                    ]}
                />
                <div className={s.details_block}>
                    <div className={s.details_row}>
                        <div className={s.detail_title}>
                            Pick-up date
                        </div>
                        <div className={s.dash}></div>
                        <div className={s.detail_value}>
                            {props.response.pickUpDate}
                            {!props.response.pickUpDate && <div>No information</div>}
                        </div>
                    </div>
                    <div className={s.details_row}>
                        <div className={s.detail_title}>
                            Pick-up location
                        </div>
                        <div className={s.dash}></div>
                        <div className={s.detail_value}>
                            {props.response.pickUpLocation}
                            {!props.response.pickUpLocation && <div>No information</div>}
                        </div>
                    </div>
                    <div className={s.details_row}>
                        <div className={s.detail_title}>
                            Status of the Shipment
                        </div>
                        <div className={s.dash}></div>
                        <div className={s.detail_value}>
                            {props.response.status === 1 && <div>Processing</div>}
                            {props.response.status === 2 && <div>In transit</div>}
                            {props.response.status === 3 && <div>Out For Delivery</div>}
                            {props.response.status === 4 && <div>Delivered</div>}
                        </div>
                    </div>
                    <div className={s.details_row}>
                        <div className={s.detail_title}>
                            Delivery date
                        </div>
                        <div className={s.dash}></div>
                        <div className={s.detail_value}>
                            {props.response.deliveryDate}
                            {!props.response.deliveryDate && <div>No information</div>}
                        </div>
                    </div>
                    <div className={s.details_row}>
                        <div className={s.detail_title}>
                            Delivery location
                        </div>
                        <div className={s.dash}></div>
                        <div className={s.detail_value}>
                            {props.response.deliveryLocation}
                            {!props.response.deliveryLocation && <div>No information</div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrackingPopup;
