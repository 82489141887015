import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import s from './Home.module.css'
import img1 from '../../assets/img/1.jpg'
import img2 from '../../assets/img/2.jpg'
import img3 from '../../assets/img/3.jpg'
import img4 from '../../assets/img/4.jpg'
import img5 from '../../assets/img/5.jpg'
import img6 from '../../assets/img/6.jpg'
import MainScreen from "../../components/MainScreen/MainScreen";
import TrackingPopup from "../../components/TrackingPopup/TrackingPopup";
import {Api} from "../../api/Api";

export type ResponseType = {
    pickUpDate: string | null,
    pickUpLocation: string | null,
    status: number,
    deliveryDate: string | null,
    deliveryLocation: string | null
}

const Home = () => {

    const [trackingNumber, setTrackingNumber] = useState("")
    const [popupIsShow, setPopupIsShow] = useState(false)
    const [error, setError] = useState(false)
    const [deliveryInfo, setDeliveryInfo] = useState<ResponseType>(
        {
            pickUpDate: "31.05.2023",
            pickUpLocation: "loc start",
            status: 3,
            deliveryDate: null,
            deliveryLocation: "loc end"
        }
    )

    const getDeliveryInfo = async () => {
        await Api.getDeliveryInfo(trackingNumber)
            .then((res: any) => {
                console.log(res)
                setDeliveryInfo(res.data)
                setPopupIsShow(true)
                setError(false)
            }).catch((e: any) => {
                console.log(e)
                setError(true)
            })
    }

    return (
        <div className={s.wrapper}>

            <MainScreen/>

            {
                popupIsShow && <TrackingPopup trackingNumber={trackingNumber}
                                              setTrackingNumber={setTrackingNumber}
                                              setPopupIsShow={setPopupIsShow}
                                              response={deliveryInfo}
                                              getDeliveryInfo={getDeliveryInfo}
                                              error={error}
                />
            }

            <div className={s.container}>
                <div className={s.search_block}>
                    <div className={s.search_title}>
                        TRACK YOUR SHIPMENT
                    </div>
                    <div className={s.flex}>
                        <input className={s.my_input}
                               type="text"
                               placeholder={"enter your shipping reference"}
                               value={trackingNumber}
                               onChange={(e) => setTrackingNumber(e.currentTarget.value)}

                        />
                        <button className={s.go_button} onClick={() => getDeliveryInfo()}>go</button>
                    </div>
                </div>

                {error && <div className={s.error}>Wrong shipping reference</div>}

                <div className={s.login_block}>
                    <div className={s.text_block}>
                        <p>
                            Hottinger Merchant Shipping & Trade Group of Companies consists of an international team of
                            experts, including logistics, security, customs house and special operations professionals,
                            who work tirelessly to ensure smooth, expedient and professional service tailored to the
                            precise specifications and needs of the global luxury goods industry and international
                            banks.
                        </p>
                        <p>
                            Hottinger Merchant Shipping & Trade stands at the forefront of industry innovation. Our
                            expert knowledge, integrated solution technology and global capabilities enables our clients
                            to achieve maximum efficiency when dealing with the movement of their valuable assets.
                        </p>
                        <p>
                            With a comprehensive international network of partners and affiliates providing an
                            unsurpassed level of customer service, Hottinger Merchant Shipping & Trade delivers absolute
                            peace of mind for those dealing in Luxury Watches, Precious Metals, Fine Arts, Special Event
                            Operations and more.
                        </p>
                    </div>
                    <div className={s.form_block}>
                        <div className={s.login_title}>Login</div>
                        <div className={s.input_label}>Login</div>
                        <input className={s.short_input} type="text"/>
                        <div className={s.input_label}>Password</div>
                        <input className={s.short_input} type="text"/>
                        <div>
                            <button className={s.submit_button}>Submit</button>
                        </div>
                    </div>
                </div>

                <div className={s.slogan}>
                    <span className={s.gold}>Hottinger Merchant Shipping & Trade</span> Delivering Absolute Peace of
                    Mind.
                </div>

                <div className={s.img_row}>
                    <div>
                        <img src={img1} alt=""/>
                        <div className={s.img_text}>Luxury Watches</div>
                    </div>
                    <div>
                        <img src={img2} alt=""/>
                        <div className={s.img_text}>Precious Metals</div>
                    </div>
                    <div>
                        <img src={img3} alt=""/>
                        <div className={s.img_text}>Special Operations</div>
                    </div>
                </div>
                <div className={s.img_row}>
                    <div>
                        <img src={img4} alt=""/>
                        <div className={s.img_text}>Vaulting Facilities</div>
                    </div>
                    <div>
                        <img src={img5} alt=""/>
                        <div className={s.img_text}>Global Network</div>
                    </div>
                    <div>
                        <img src={img6} alt=""/>
                        <div className={s.img_text}>Videos</div>
                    </div>
                </div>
            </div>

            <div className={s.pre_footer}>
                <div className={s.container}>
                    <div className={s.pre_footer_wrapper}>
                        <div className={s.links_column}>
                            <div className={s.links_block}>
                                <div className={s.links_title}>Luxury Watches</div>
                                <NavLink className={s.footer_link} to={"#"}>Secured Delivery</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>VIC SHIPPING Express</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>FTZ & Secure Storage</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>Third Party Logistics (3PL)</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>Jet Service</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>Guarding Services</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>Trade Show Packages</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>Gem Lab Services</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>Inspection Services</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>Customs Brokerage</NavLink>
                            </div>

                        </div>
                        <div className={s.links_block}>
                            <div className={s.links_block}>
                                <div className={s.links_title}>Precious Metals</div>
                                <NavLink className={s.footer_link} to={"#"}>Vaults & FTZ Facilities</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>Secured Delivery</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>Inspection Services</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>VIC SHIPPING Precious Metals
                                    Services</NavLink>
                            </div>

                            <div className={s.links_block}>
                                <div className={s.links_title}>Special Operations</div>
                                <NavLink className={s.footer_link} to={"#"}>Private</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>Events</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>Travelling Exhibitions</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>Fine Arts & Rarities</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>Guarding</NavLink>
                            </div>
                        </div>
                        <div className={s.links_block}>
                            <div className={s.links_block}>
                                <div className={s.links_title}>Vaulting Facilities</div>
                                <NavLink className={s.footer_link} to={"#"}>Cyprus</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>Italy</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>France</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>Estonia</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>Monaco</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>Spain</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>Bulgaria</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>Rumenia</NavLink>
                            </div>

                            <div className={s.links_block}>
                                <div className={s.links_title}>Global Network</div>
                                <NavLink className={s.footer_link} to={"#"}>Africa</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>Asia-Pacific</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>Europe</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>North America</NavLink>
                            </div>
                        </div>
                        <div className={s.links_block}>
                            <div className={s.links_block}>
                                <div className={s.links_title}>Home</div>
                                <NavLink className={s.footer_link} to={"#"}>About</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>Videos</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>History</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>Media</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>Login to my VIC</NavLink>
                            </div>

                            <div className={s.links_block}>
                                <div className={s.links_title}>Legal</div>
                                <NavLink className={s.footer_link} to={"#"}>Terms & Conditions</NavLink>
                                <NavLink className={s.footer_link} to={"#"}>Privacy Policy</NavLink>
                            </div>
                        </div>
                        <div>
                            <button className={s.submit_button}>LOGIN TO MY VIC</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={s.footer}>
                <div className={s.container}>
                    <p className={s.footer_text}>
                        This Entire Site and all Contents Copyright 2023 by HOTTINGER MERCHANT SHIPPING & TRADE" Ltd.
                        <span className={s.terms}> | TERMS AND CONDITIONS</span>
                    </p>
                    <p className={s.footer_text}>
                        Disclaimer Please be aware of companies and individuals fraudulently claiming to operate or
                        represent HOTTINGER MERCHANT SHIPPING & TRADE" Ltd. offices. These companies and individuals are
                        not affiliated with "HOTTINGER MERCHANT SHIPPING & TRADE" Ltd. and may not be operating legally.
                        Only those "HOTTINGER MERCHANT SHIPPING & TRADE" Ltd. companies and offices listed in this
                        website are part of the HOTTINGER MERCHANT SHIPPING & TRADE. Network™. If you doubt the
                        legitimacy of any company or individual claiming to be part of the HOTTINGER MERCHANT SHIPPING &
                        TRADE Network™, please email us immediately at
                        <span className={s.terms}> hottingersk@gmail.com</span>.
                    </p>
                </div>
            </div>

        </div>
    );
};

export default Home;
