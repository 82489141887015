import React from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import Home from "./pages/Home/Home";
import Contacts from "./pages/Contacts/Contacts";

function App() {
  return (
    <div className="App">
        <Routes>
            <Route index element={<Home/>}/>
            <Route path={'/contact_us'} element={<Contacts/>}/>
        </Routes>
    </div>
  );
}

export default App;
