import axios from "axios";

let baseSettings = {
    headers: {
        'Content-Type': 'application/json',
    },
}

const instance = axios.create({
    baseURL: 'https://api.hottinger-shipping.com',
    ...baseSettings
}) as any

const instanceSender = axios.create({
    baseURL: 'https://assembly.tehnik.tech',
    ...baseSettings
}) as any

export const Api = {
    getDeliveryInfo(code: string) {
        return instance.post('/api/deliveries/infos', {code: code})
    },
    sendContactMessage(data: any) {
        return instanceSender.post('/api/send_form/from', data)
    }
}


